import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';

const App = () => {
  const [totalInvested, setTotalInvested] = useState(332); // Example hardcoded value in USDC
  const [portfolioBalance, setPortfolioBalance] = useState(0); // Initial value will be fetched
  const [calculatedROI, setCalculatedROI] = useState(0);
  const [investedAmount, setInvestedAmount] = useState('');
  const [roiResult, setRoiResult] = useState(0);
  const [copied, setCopied] = useState(false);

  const cryptoAddress = "0xd36918B9A4834bE0171F3B23E67634A8929A2108";
  const telegramNumber = "+376 616 006";
  const accessKey = "a78b5db6b62798deb93e2e194a5420badc280f95";

  // Function to fetch the portfolio balance from Debank API
  const fetchPortfolioBalance = async () => {
    try {
      const cachedData = localStorage.getItem('portfolioBalance');
      const cachedTime = localStorage.getItem('portfolioBalanceTime');
      const currentTime = new Date().getTime();

      // Use cached data if it's less than 1 hour old
      if (cachedData && cachedTime && currentTime - cachedTime < 3600000) {
        const portfolioValue = JSON.parse(cachedData);
        setPortfolioBalance(Math.floor(portfolioValue));
        setCalculatedROI(Math.floor(portfolioValue / totalInvested));
      } else {
        const response = await axios.get(
          `https://pro-openapi.debank.com/v1/user/total_balance?id=${cryptoAddress}`,
          {
            headers: {
              accept: 'application/json',
              AccessKey: accessKey,
            },
          }
        );
        const portfolioValue = response.data.total_usd_value;
        setPortfolioBalance(Math.floor(portfolioValue));
        setCalculatedROI(Math.floor(portfolioValue / totalInvested));

        // Cache the fetched data
        localStorage.setItem('portfolioBalance', JSON.stringify(portfolioValue));
        localStorage.setItem('portfolioBalanceTime', currentTime.toString());
      }
    } catch (error) {
      console.error('Error fetching portfolio balance:', error);
    }
  };

  // Fetch portfolio balance on component mount
  useEffect(() => {
    fetchPortfolioBalance();
  }, []);

  const handleCalculation = () => {
    if (investedAmount > 0) {
      setRoiResult(Math.floor(investedAmount * calculatedROI));
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(cryptoAddress).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }, (err) => {
      console.error('Failed to copy address!', err);
    });
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>LMPR Capital</h1>
      </header>
      
      <section>
        <div>
          <h2>Total USDC Invested: ${totalInvested}</h2>
        </div>
        <div>
          <h2>Portfolio Value: ${portfolioBalance}</h2>
        </div>
        <div>
          <h2>ROI: {calculatedROI}x</h2>
        </div>
      </section>

      <section>
        <h2>Investment Calculator</h2>
        <div>
          <label>
            Amount Invested:
            <input 
              type="number" 
              value={investedAmount} 
              onChange={(e) => setInvestedAmount(e.target.value)} 
            />
          </label>
          <button onClick={handleCalculation}>Calculate</button>
        </div>
        {roiResult > 0 && (
          <div>
            <h3>Returns: ${roiResult}</h3>
          </div>
        )}
      </section>

      <section>
        <h2>Invest in the Fund</h2>
        <div>
          <p>Send USDC to the following address:</p>
          <p className="crypto-address">
            {cryptoAddress} 
            <i className={`fas ${copied ? 'fa-check' : 'fa-copy'} copy-icon`} onClick={copyToClipboard}></i>
          </p>
          <p>Please send a Telegram message to <strong>{telegramNumber}</strong> with the following format before investing:</p>
          <p><strong>NAME : AMOUNT</strong></p>
        </div>
      </section>

      <section>
        <h2>Disclaimer</h2>
        <div>
          <ul className="disclaimer-list">
            <li>Only USDC is allowed for investments.</li>
            <li>10% of profit is taken as fee (deducted every 6 months).</li>
            <li><strong><em>INVEST ONLY WHAT YOU ARE WILLING TO LOSE.</em></strong></li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default App;
